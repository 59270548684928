<template>
  <section
    class="parallaxevsa"
    id="section4"
  >
    <!-- <img src="@/assets/hero.jpeg" alt=""> -->
    <h2 class="trayectoria-titulo text-center pt-3">Más de 60 años de trayectoria</h2>
    <div class="trayectoria-contenedor">
      <p class="trayectoria-texto">
        "Nuestra experiencia nos permite ofrecer a la pequeña y mediana empresa soluciones informáticas en la modalidad <span class="destacado"> "llave en mano", </span> combinando el <span class="destacado"> mejor hardware </span> en el mercado, con <span class="destacado"> nuestro software de gestión </span>, y un <span class="destacado">servicio de mantenimiento" </span>
      </p>
    </div>

  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  padding-top: 2em;
  padding-bottom: 2em;
  @media (max-width: 400px) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.destacado {
  font-weight: 700;
}

#section4 {
  height: 300px;

  background: #000046; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #0986a8,
    #000046
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #0986a8,
    #000046
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    height: 400px;
  }
}

.trayectoria {
  &-titulo {
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 2em;
    color: white;
  }

  &-contenedor {
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    text-align: center;

    @media (max-width: 600px) {
      width: 90%;
    }
  }

  &-texto {
    font-family: "Krub", sans-serif;

    letter-spacing: 1px;
    font-size: 1.3em;
    color: white;
    font-style: italic;
    line-height: 2;

    @media (max-width: 600px) {
      font-size: 1em;
    }
  }
}
</style>
