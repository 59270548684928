import { render, staticRenderFns } from "./app-inicio.vue?vue&type=template&id=b2a9418c&scoped=true&"
import script from "./app-inicio.vue?vue&type=script&lang=js&"
export * from "./app-inicio.vue?vue&type=script&lang=js&"
import style0 from "./app-inicio.vue?vue&type=style&index=0&id=b2a9418c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2a9418c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCarousel,VCarouselItem,VDialog})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
