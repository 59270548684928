<!--
  Componente evsaheader
  Contiene el toolbar y un header ("section1") donde va el logo del sitio.
  Creado por Alejandro y Fernanda
  Ultima Actualizacion: 05/10/2018
-->

<template>

  <div>
    <!--
    <v-navigation-drawer
      v-model="drawer"
      right=""
      app
      class="hidden-lg-and-up"
    >

      <div class="sidebar">
        <v-btn
          class="botonMenu"
          id="btnNos"
          color="primary"
          text
          @click="$vuetify.goTo('#Nosotros', scrollingOptions) ; drawer = false"
        >Nosotros</v-btn>
        <v-btn
          class="botonMenu"
          id="btnSer"
          color="primary"
          text
          @click="$vuetify.goTo('#Servicios', scrollingOptions) ; drawer = false"
        >Servicios</v-btn>
        <v-btn
          class="botonMenu"
          id="btnCli"
          color="primary"
          text
          @click="$vuetify.goTo('#Clientes', scrollingOptions) ; drawer = false"
        >Clientes</v-btn>
        <v-btn
          class="botonMenu"
          id="btnCon"
          color="primary"
          text
          @click="$vuetify.goTo('#Contacto', scrollingOptions) ; drawer = false"
        >Contacto</v-btn>
      </div>

    </v-navigation-drawer>
`-->
    <!--
    <div class="toolbar hidden-md-and-down green">
      -->
    <v-app-bar
      app
      :height="90"
      class="hidden-sm-and-down"
      color="white"
    >
      <v-toolbar-title>
        <img
          src="@/assets/logoevsa.png"
          alt="Logo EVSA"
          class="my-auto ml-3"
        >
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!--
      <div class="toolbar-botones">
        -->
      <v-btn
        class="botonMenu"
        id="btnNos"
        color="primary"
        text
        @click="$vuetify.goTo('#Nosotros', scrollingOptions)"
      >Nosotros</v-btn>
      <v-btn
        class="botonMenu"
        id="btnSer"
        color="primary"
        text
        @click="$vuetify.goTo('#Servicios', scrollingOptions)"
      >Servicios</v-btn>
      <v-btn
        class="botonMenu"
        id="btnCli"
        color="primary"
        text
        @click="$vuetify.goTo('#Clientes', scrollingOptions)"
      >Clientes</v-btn>
      <v-btn
        class="botonMenu"
        id="btnCon"
        color="primary"
        text
        @click="$vuetify.goTo('#Contacto', scrollingOptions)"
      >Contacto</v-btn>
      <!--
      </div>
      -->

    </v-app-bar>

    <!--
    <v-toolbar class="hidden-lg-and-up toolbar-chico">
      -->

    <v-app-bar
      app
      class="hidden-md-and-up"
    >

      <img
        src="@/assets/logoevsa.png"
        alt="Logo EVSA"
        class="my-auto ml-1 logochico"
      >
      <v-spacer></v-spacer>
      <v-btn
        icon=""
        text=""
        @click.stop="drawer = !drawer"
      ><i class="fas fa-bars"></i></v-btn>
    </v-app-bar>

    <section id="section1">

      <div class="header-overlay">

        <div class="header-titulo-contenedor">

          <h1 class="white--text header-titulo">
            TECNOLOGIA A LA MEDIDA DE SU EMPRESA
          </h1>
        </div>

        <h2 class="white--text text-center header-subtitulo">
          Desarrollamos e implementamos soluciones de sistemas de software personalizado, que se integra perfectamente con el hardware que vendemos, y ofrecemos servicio técnico para ambos.
        </h2>

        <div class="header-texto">

        </div>

      </div>

    </section>

  </div>

</template>

<script>
// import * as easings from "vuetify/es5/util/easing-patterns";

export default {
  computed: {
    scrollingOptions () {
      return {
        duration: 1000,
        offset: -100,
        // easing: this.easing
      };
    }
  },
  data () {
    return {
      type: "number",
      number: 9999,
      selector: "#first",
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 300,
      offset: 0,
      // easing: "easeInOutCubic",
      // easings: Object.keys(easings),
      title: "EVSA",
      drawer: null,
      items: [
        { title: "Home", icon: "dashboard" },
        { title: "About", icon: "question_answer" }
      ],
      mini: true,
      right: null,
      target: "contacto",
      year: new Date().getFullYear()
    };
  }
};
</script>

<style lang="scss" scoped>
$color-1: #0e40c6;
$color-2: #380ec6;
$color-3: #940ec6;
$color-4: #c60e9c;
$color-5: #c60e40;
$color-6: #c6380e;

.logochico {
  height: 40px;
}

.sidebar {
  margin-top: 3.5em;
  display: flex;
  flex-direction: column;
}

.toolbar {
  background-color: white;
  height: 90px;
  font-family: "Poppins", sans-serif;
  color: blue;
  width: 100%;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &-chico {
    position: fixed;
    z-index: 999;
    background: white;
    height: 50px;
  }

  &-botones {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &-title {
    font-family: "Sawarabi Gothic", sans-serif;
    font-size: 2.5em;
    padding-left: 1em;
    letter-spacing: 2px;
    font-variant: small-caps;
  }
}

#section1 {
  width: 100%;
  /* background: rgba(2,81,161,1);
background: -moz-linear-gradient(top, rgba(2,81,161,1) 0%, rgba(4,73,130,1) 22%, rgba(15,54,89,1) 51%, rgba(0,24,46,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(2,81,161,1)), color-stop(22%, rgba(4,73,130,1)), color-stop(51%, rgba(15,54,89,1)), color-stop(100%, rgba(0,24,46,1)));
background: -webkit-linear-gradient(top, rgba(2,81,161,1) 0%, rgba(4,73,130,1) 22%, rgba(15,54,89,1) 51%, rgba(0,24,46,1) 100%);
background: -o-linear-gradient(top, rgba(2,81,161,1) 0%, rgba(4,73,130,1) 22%, rgba(15,54,89,1) 51%, rgba(0,24,46,1) 100%);
background: -ms-linear-gradient(top, rgba(2,81,161,1) 0%, rgba(4,73,130,1) 22%, rgba(15,54,89,1) 51%, rgba(0,24,46,1) 100%);
background: linear-gradient(to bottom, rgba(2,81,161,1) 0%, rgba(4,73,130,1) 22%, rgba(15,54,89,1) 51%, rgba(0,24,46,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0251a1', endColorstr='#00182e', GradientType=0 ); */
  margin-top: 90px;
  /* background-image: linear-gradient(
    to bottom,
    rgb(25, 30, 61) 0%,
    rgb(21, 41, 151) 71%,
    rgb(19, 71, 129) 100%
  ); */
  background: url("~../assets/header.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-top: 0;
    height: 400px;
  }
}

.header {
  &-overlay {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba($color: #000000, $alpha: 0.8);
    height: 600px;

    @media (max-width: 600px) {
      margin-top: 0;
      height: 400px;
    }
  }

  &-texto {
    margin: 0 auto;
    width: 80%;
  }

  &-titulo {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    letter-spacing: 1px;
    font-size: 4em;
    font-weight: 800;

    &-contenedor {
      width: 80%;
    }

    @media (max-width: 600px) {
      font-size: 2.3rem;
      font-weight: 900;
      width: 80%;
      margin: 0 auto;
    }
  }

  &-subtitulo {
    font-family: "Poppins", sans-serif;
    width: 80%;
    letter-spacing: 1px;
    font-weight: 400;
    margin-top: 1em;
    font-size: 1.4em;

    @media (max-width: 600px) {
      font-size: 1em;
    }
  }
}

.botonMenu {
  border: 2px solid transparent;
  font-size: 1.4em;
}

#btnNos:hover {
  border-bottom: 2px solid $color-2;
}

#btnSer:hover {
  border-bottom: 2px solid $color-3;
}

#btnCli:hover {
  border-bottom: 2px solid $color-4;
}

#btnCon:hover {
  border-bottom: 2px solid $color-5;
}
</style>

