// ./plugins/vuetify.js

// GGG Preferimos incluir los iconos dentro de la aplicacion
// en lugar de usar en /build/index.htm:
// <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css">
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import Vue from "vue";
import Vuetify from "vuetify/lib";

import es from "vuetify/src/locale/es.ts";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { es },
    current: "es",
  },

  theme: {
    themes: {
      light: {
        primary: "#0e40c6",
        secondary: "#380ec6",
        terciary: "#940ec6",
        cuarto: "#c60e9c",
        quinto: "#c60e40",
        sexto: "#c10707",
        accent: "#9c27b0",
        error: "#f44336",
        warning: "#ffeb3b",
        info: "#2196f3",
        success: "#4caf50",
      },
      dark: {
        primary: "#0e40c6",
        secondary: "#380ec6",
        terciary: "#940ec6",
        cuarto: "#c60e9c",
        quinto: "#c60e40",
        sexto: "#c10707",
        accent: "#9c27b0",
        error: "#f44336",
        warning: "#ffeb3b",
        info: "#2196f3",
        success: "#4caf50",
      },
    },
  },
});
