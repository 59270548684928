<template>
  <footer class="footer">

    <div class="links">
      <button
        class="enlace"
        text
        @click="$vuetify.goTo('#Nosotros', scrollingOptions)"
      >Nosotros</button>
      <button
        class="enlace"
        text
        @click="$vuetify.goTo('#Servicios', scrollingOptions)"
      >Servicios</button>
      <button
        class="enlace"
        text
        @click="$vuetify.goTo('#Clientes', scrollingOptions)"
      >Clientes</button>
      <button
        class="enlace"
        text
        @click="$vuetify.goTo('#Contacto', scrollingOptions)"
      >Contacto</button>
    </div>

    <div class="copyright mt-4 mb-0">
      <span class="white--text">Electrónica Valle - Copyright {{ year }}</span>
    </div>

    <div class="vueti hidden-sm-and-down white--text mt-3">

      <small class="mt-2">
        Icons made by <a
          href="https://www.flaticon.com/authors/gregor-cresnar"
          title="Gregor Cresnar"
        >Gregor Cresnar</a> from <a
          href="https://www.flaticon.com/"
          title="Flaticon"
        >www.flaticon.com</a>
      </small>

      <small class="mt-2"> Powered by Vuetify</small>
      <img
        src="../assets/vuetify.png"
        alt=""
        width="22px"
        height="22px"
      >

      <small class="white--text mt-2">
        Photo by freestocks.org on Unsplash
      </small>

    </div>

  </footer>

</template>

<script>
// import * as easings from "vuetify/es5/util/easing-patterns";

export default {
  name: 'footer-evsa',
  computed: {
    scrollingOptions () {
      return {
        duration: 1000,
        offset: -100,
        // easing: this.easing
      };
    }
  },
  data () {
    return {
      type: "number",
      number: 9999,
      selector: "#first",
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 300,
      offset: 0,
      // easing: "easeInOutCubic",
      // easings: Object.keys(easings),
      title: "EVSA",
      drawer: true,
      items: [
        { title: "Home", icon: "dashboard" },
        { title: "About", icon: "question_answer" }
      ],
      mini: true,
      right: null,
      target: "contacto",
      year: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
a,
a:visited,
a:link {
  color: palevioletred;
  text-decoration: none;
}

.footer {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  /*Fondo*/
  background: rgb(10, 50, 156);
  background: linear-gradient(
    102deg,
    rgba(10, 50, 156, 1) 0%,
    rgba(9, 9, 121, 1) 100%,
    rgba(5, 101, 182, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
}

.links {
  display: flex;
}

.copyright,
.vueti {
  display: flex;
  font-family: "Montserrat", sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enlace {
  margin: 2em;
  font-size: 1.2em;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: color 0.25s ease-in;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.enlace:hover {
  color: #c60e9c;
}

@media (max-width: 700px) {
  .links {
    margin-top: 10em;
    flex-direction: column;
    margin-bottom: 2em;
  }

  .enlace {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }

  .copyright {
    margin-top: 3em;
  }

  .vueti {
    margin-bottom: 1em;
  }
}
</style>
