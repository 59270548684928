<template>
  <section
    class="parallaxevsa"
    id="section3"
  >
    <v-container grid-list-xl>
      <h2 class="titulo-blanco text-center pt-3">Hardware</h2>

      <div class="texto text-center mt-2">Con más de 60 años de experiencia, somos expertos en venta de hardware para mercados verticales.</div>

      <v-row
        row
        wrap
        justify-center
        style="width: 80%; margin: 0 auto;"
      >
        <v-flex xs12>
          <v-card class="elevation-0 transparent">

            <div class="productos">

              <div class="productos-circulo">
                <img
                  src="@/assets/balanza.svg"
                  class="productos-img"
                  alt="balanza"
                >
                <p class="productos-texto">Balanzas</p>
              </div>

              <div class="productos-circulo">
                <img
                  src="@/assets/cajaregis.svg"
                  class="productos-img"
                  alt="balanza"
                >
                <p class="productos-texto">Cajas registradoras</p>
              </div>

              <div class="productos-circulo">
                <img
                  src="@/assets/controfis.svg"
                  class="productos-img"
                  alt="balanza"
                >
                <p class="productos-texto">Controladoras fiscales</p>
              </div>

              <div class="productos-circulo">
                <img
                  src="@/assets/pos2.svg"
                  class="productos-img"
                  alt="balanza"
                >
                <p class="productos-texto">Puntos de venta</p>
              </div>

              <div class="productos-circulo">
                <img
                  src="@/assets/scanner.svg"
                  class="productos-img"
                  alt="balanza"
                >
                <p class="productos-texto">Escáneres</p>
              </div>

              <div class="productos-circulo">
                <img
                  src="@/assets/servidor.svg"
                  class="productos-img"
                  alt="balanza"
                >
                <p class="productos-texto">Servidores</p>
              </div>

            </div>

          </v-card>
        </v-flex>

      </v-row>
    </v-container>

  </section>

</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background-color: rgb(100, 100, 100);
  padding-top: 2em;
  padding-bottom: 2em;
  @media (max-width: 400px) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#hasar {
  height: 100px;
}

#hp {
  filter: brightness(0) invert(1);
  margin-right: 10px;
}

.titulo-blanco {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2em;
  color: white;
}

.productos {
  display: flex;
  margin-top: 3em;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
    margin-top: 0.5em;
  }

  &-circulo {
    display: flex;
    flex-direction: column;
    margin-right: 35px;

    @media (max-width: 400px) {
      margin-right: 10px;
    }
  }

  &-img {
    height: 50px;

    @media (max-width: 400px) {
      height: 35px;
    }
  }

  &-texto {
    margin-top: 0.5em;
    color: white;
    font-family: "Montserrat", sans-serif;
  }
}

.texto {
  font-family: "Montserrat", sans-serif;

  @media (max-width: 600px) {
    font-size: 1em;
  }
}

.subtitulo {
  font-family: "Poppins", sans-serif;
  margin-top: 1em;
  letter-spacing: 1px;
  font-size: 1.5em;
  color: #215ddf;
}

.marcas-contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 600px) {
    flex-direction: row;
  }
}

.marcasimg {
  max-width: 200px;
  height: 80px;

  @media (max-width: 600px) {
    height: 60px;
    max-width: 120px;
  }
}

#section3 {
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;

  background: #000046; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #1cb5e0,
    #000046
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #1cb5e0,
    #000046
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 600px) {
    height: 650px;
  }
}
</style>
