<template>

  <section>

    <v-row
      column
      wrap
      align-center
    >

      <v-flex
        xs12
        class="pt-3"
      >
        <div class="text-center">
          <h2
            class="titulo pt-3"
            id="Nosotros"
          >El sistema que su negocio necesita</h2>
          <v-card-text class="text-center texto">
            Electrónica Valle desarrolla, implementa y soporta proyectos relacionados con la electrónica y el software.
          </v-card-text>
        </div>
      </v-flex>

      <v-flex xs12>
        <v-container grid-list-xl>
          <v-row
            row
            wrap
            align-center
          >

            <v-flex
              xs12
              md4
            >
              <v-card class="elevation-0 transparent">
                <v-card-text class="text-center">

                  <i class="fas icono-carac fa-5x fa-laptop-code primary--text"></i>
                </v-card-text>
                <v-card-title
                  primary-title
                  class="layout justify-center"
                >
                  <div class="subtitulo">Desarrollo de Software</div>
                </v-card-title>
                <v-card-text class="text-center texto">
                  Contamos con más de 35 años de experiencia desarrollando software personalizado.

                </v-card-text>

              </v-card>
            </v-flex>

            <v-flex
              xs12
              md4
            >
              <v-card class="elevation-0 transparent">
                <v-card-text class="text-center">
                  <i class="fas icono-carac fa-5x fa-microchip primary--text"></i>

                </v-card-text>
                <v-card-title
                  primary-title
                  class="layout justify-center"
                >
                  <div class="subtitulo">Venta de Hardware</div>
                </v-card-title>
                <v-card-text class="text-center texto">
                  Ofrecemos hardware para mercados verticales (registradoras, puntos de venta, escáner y otros)
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              md4
            >
              <v-card class="elevation-0 transparent">
                <v-card-text class="text-center">
                  <i class="fas icono-carac fa-5x fa-toolbox primary--text"></i>
                </v-card-text>
                <v-card-title
                  primary-title
                  class="layout justify-center"
                >
                  <div class="subtitulo text-center">Mantenimiento</div>
                </v-card-title>
                <v-card-text class="text-center texto">
                  Brindamos mantemiento para nuestros programas y servicio técnico para el hardware.
                </v-card-text>
              </v-card>
            </v-flex>
          </v-row>
        </v-container>
      </v-flex>
    </v-row>

  </section>

</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$color-1: #0e40c6;
$color-2: #380ec6;
$color-3: #940ec6;
$color-4: #c60e9c;
$color-5: #c60e40;
$color-6: #c6380e;
section {
  background-color: rgb(236, 236, 236);
  padding-top: 2em;
  padding-bottom: 2em;
  @media (max-width: 400px) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.icono-carac {
  transition: transform 0.2s ease-in;

  &:hover {
    transform: scale(1.1);
  }
}

.titulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2em;
  color: $color-1;
}

.subtitulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 1.5em;
}

.fa-microchip {
  color: #5611ba;
}

.texto {
  font-family: "Montserrat", sans-serif;

  @media (max-width: 600px) {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .fa-5x {
    font-size: 3em;
  }

  .v-card__text {
    padding-top: 1em;
    font-size: 1em;
  }

  .v-card__title {
    padding: 0;
  }
}
</style>
