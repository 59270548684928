<template>
  <section>
    <v-container fluid>

      <h2
        class="titulo text-center pt-3"
        id="Contacto"
      >Contacto</h2>

      <v-row
        justify="center"
        center
        class="my-5"
      >
        <v-col
          cols="12"
          sm="4"
        >

          <v-card class="elevation-0 transparent">
            <v-card-text class="text-center subtitulo">
              Escríbanos su consulta aquí:
            </v-card-text>

            <c-formulario></c-formulario>

          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >

          <v-card class="elevation-0 transparent">
            <v-card-text class="subtitulo">
              También puede contactarnos por estos medios:
            </v-card-text>
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-action>
                  <i class="fas fa-phone fa-flip-horizontal primary--text"></i>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="contactoItems texto">0381 230-8659 </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <i class="fas fa-envelope secondary--text"></i>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="contactoItems texto">correo@electronicavalle.com.ar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-text class="subtitulo">
              O acercarse a nuestro local: </v-card-text>
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-action>
                  <i class="fas fa-map-marker-alt terciary--text"></i>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="contactoItems texto">Crisóstomo Álvarez 264</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <i class="fas fa-globe-americas cuarto--text"></i>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="contactoItems texto">S.M. de Tucumán, Tucumán</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <i class="fas fa-clock quinto--text"></i>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="contactoItems texto">Lun - Vie, de 08 a 17</v-list-item-title>
                  <v-list-item-title class="contactoItems texto">Sab. de 08:30 a 12:30</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import cFormulario from "@/components/c-formulario";

export default {
  components: {
    cFormulario
  }
};
</script>

<style lang="scss" scoped>
$color-1: #0e40c6;
section {
  background-color: rgb(236, 236, 236);
  padding-top: 2em;
  padding-bottom: 2em;
  @media (max-width: 400px) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.titulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2em;
  color: $color-1;
}

.subtitulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 1.5em;
}

.texto {
  font-family: "Montserrat", sans-serif;

  @media (max-width: 600px) {
    font-size: 1em;
  }
}

.contactoItems {
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
}
</style>

