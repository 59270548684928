<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Nombre"
        required
      ></v-text-field>
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="Correo"
        required
      ></v-text-field>
      <v-textarea
        v-model="mensaje"
        :rules="mensajeRules"
        name="input-7-1"
        label="Mensaje"
        value=""
        required
      ></v-textarea>
      <div class="text-center">
        <v-btn
          :disabled="!valid"
          @click="submit"
          class="btn-grad white--text"
          color="primary"
        >
          Enviar
        </v-btn>
        <v-btn
          @click="clear"
          color="secondary"
          class="btn-canc white--text"
        >
          Limpiar
        </v-btn>
      </div>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      bottom=""
      vertical=""
      multi-line=""
      color="primary"
    >
      <h2 class="texto">¡Gracias por contactarse con nosotros!</h2>
      <v-btn
        text
        color="primary darken-3"
        @click.native="snackbar = false"
      >Cerrar</v-btn>
    </v-snackbar>

  </div>

</template>


<script>
/* import axios from "axios";

export default {
  data: () => ({
    valid: null,
    name: "",
    nameRules: [v => !!v || "El nombre es obligatorio"],
    email: "",
    mensaje: "",
    emailRules: [
      v => !!v || "El correo es obligatorio",
      v => /.+@.+/.test(v) || "Ingrese un correo válido"
    ]
  }),
 */
import axios from "axios";

export default {
  data: () => ({
    valid: true,
    snackbar: false,
    name: "",
    nameRules: [v => !!v || "El nombre es un campo obligatorio"],
    mensaje: "",
    mensajeRules: [v => !!v || "Por favor, ingrese su mensaje"],
    email: "",
    emailRules: [
      v => !!v || "El correo es un campo obligatorio",
      v => /.+@.+/.test(v) || "Ingrese un correo válido"
    ],
  }),
  methods: {
    submit () {
      console.log("Entra al submit!!!");

      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported

        const fd = new FormData();
        fd.append("nombre", this.name);
        fd.append("correo", this.email);

        console.log("MENSAJE:", this.mensaje);

        fd.append("mensaje", this.mensaje);

        axios.post("/rpr-evsa/sitio/api/submitContacto", fd).then(res => {
          console.log(["response: ", res]);
          this.snackbar = true;
        });
        this.$refs.form.reset();
      }
    },
    clear () {
      this.$refs.form.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-grad {
  flex: 1 1 auto;
  margin: 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.btn-canc {
  flex: 1 1 auto;
  margin: 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.texto {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2em;

  @media (max-width: 600px) {
    font-size: 1em;
  }
}
</style>
