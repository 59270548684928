<template>
  <div>
    <!--
    <v-navigation-drawer
      v-model="drawer"
      app
      disable-resize-watcher
    >
      <div class="linksDrawer">
        <h2 class="subtitulo titulo-drawer">Menú</h2>
        <v-btn
          text
          class="botonesDrawer"
          color="primary"
          @click="$vuetify.goTo('#Nosotros', scrollingOptions)"
        >Nosotros</v-btn>
        <v-btn
          text
          class="botonesDrawer"
          color="primary"
          @click="$vuetify.goTo('#Servicios', scrollingOptions)"
        >Servicios</v-btn>
        <v-btn
          text
          class="botonesDrawer"
          color="primary"
          @click="$vuetify.goTo('#Clientes', scrollingOptions)"
        >Clientes</v-btn>
        <v-btn
          text
          class="botonesDrawer"
          color="primary"
          @click="$vuetify.goTo('#Contacto', scrollingOptions)"
        >Contacto</v-btn>
      </div>
    </v-navigation-drawer>
  -->

    <evsa-header v-show="dialog === false"></evsa-header>

    <v-dialog
      v-model="dialog"
      v-if="windowSize.x < 800"
      v-resize="onResize"
      width="100%"
      hide-controls
    >
      <v-card class="dialog">
        <v-carousel
          next-icon="fas fa-chevron-right"
          prev-icon="fas fa-chevron-left"
          delimiter-icon="fas fa-circle"
        >

          <v-carousel-item>
            <img
              src="@/assets/bannerVertical111.jpg"
              alt=""
            >
          </v-carousel-item>
          <v-carousel-item>
            <img
              src="@/assets/bannervertical3.jpg"
              alt=""
            >
          </v-carousel-item>
          <v-carousel-item>
            <img
              src="@/assets/bannerVertical2.jpg"
              alt=""
            >
          </v-carousel-item>
        </v-carousel>
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="dialog"
      v-resize="onResize"
      v-if="windowSize.x >= 800"
      max-width="800px"
      height="550px"
    >
      <v-carousel
        next-icon="fas fa-chevron-right"
        prev-icon="fas fa-chevron-left"
        delimiter-icon="fas fa-circle"
        hide-delimiters=""
        height="550px"
      >

        <v-carousel-item>
          <img
            src="@/assets/bannerEVSA.jpg"
            alt=""
          >
        </v-carousel-item>
        <v-carousel-item>
          <img
            src="@/assets/numa.jpg"
            alt=""
          >
        </v-carousel-item>
        <v-carousel-item>
          <img
            class="carousel__imagen"
            src="@/assets/evsaBanner2.jpg"
            alt=""
          >
        </v-carousel-item>
      </v-carousel>

    </v-dialog>

    <button
      onclick='window.scrollTo({top: 0, behavior: "smooth"}); return false;'
      id="myBtn"
      title="Volver arriba"
    >
      <i
        class="fa fa-angle-up"
        aria-hidden="true"
      ></i>
    </button>

    <c-nosotros></c-nosotros>

    <c-servicios></c-servicios>

    <c-software></c-software>

    <c-hardware></c-hardware>

    <c-clientes></c-clientes>

    <c-trayectoria></c-trayectoria>

    <c-contacto></c-contacto>

    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.214659921191!2d-65.20381368580443!3d-26.83312398316191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225c054171f5c3%3A0xfe72e29f08418aa9!2sELECTRONICA+VALLE+S.A.!5e0!3m2!1ses!2sar!4v1534254134493"
        class="mapa"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></iframe>
    </div>

    <footer-evsa></footer-evsa>
  </div>
</template>

<script>
import evsaHeader from "@/components/evsa-header";
import footerEvsa from "@/components/footer-evsa";
import cClientes from "@/components/c-clientes";
import cNosotros from "@/components/c-nosotros";
import cServicios from "@/components/c-servicios";
import cSoftware from "@/components/c-software";
import cHardware from "@/components/c-hardware";
import cContacto from "@/components/c-contacto";
import cTrayectoria from "@/components/c-trayectoria";

export default {
  components: {
    footerEvsa,
    evsaHeader,
    cClientes,
    cNosotros,
    cServicios,
    cHardware,
    cSoftware,
    cContacto,
    cTrayectoria
  },

  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    }
  },

  computed: {
    scrollingOptions () {
      return {
        duration: 1000,
        offset: -20,
        easing: this.easing
      };
    }
  },

  /*   created() {
    window.onscroll = function() {
      scrollFunction();
    };
  },
 */
  props: {
    desarrollo: {
      type: String,
      default: "$vuetify.icons.desarrollo"
    }
  },

  mounted () {
    // this.dialog = true
    this.dialog = false
    this.onResize()
  },

  data () {
    return {
      windowSize: {
        x: 0,
        y: 0
      },
      drawer: false,
      dialog: true,
      easing:
        "easeInOutCubic",
      items: [
        {
          src: '@/assets/kretz.png'
        },
        {
          src: '@/assets/kretz.png'
        },
        {
          src: '@/assets/kretz.png'
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
section {
  background-color: rgb(236, 236, 236);
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.v-dialog {
  width: 50%;
  height: 200px;
}

.carousel {
  &__imagen {
    background: white;
    width: 100%;
    height: auto;
  }
}

.publicidad {
  &__imagen {
    height: 600px;
    margin: 0 auto;
  }
}

.dialog {
  background: rgb(2, 0, 36);
  color: white;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 39%,
    rgb(17, 105, 206) 100%
  );

  &--destacado {
    color: rgb(22, 238, 238);
    font-weight: 900;
  }

  &__titulo {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-size: 2.8rem;
    text-transform: uppercase;
    text-align: center;
  }

  &__subtitulo {
    font-size: 1.5rem;
  }
}

.linksDrawer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;

  @media (max-width: 600px) {
    align-items: center;
  }
}

.botonesDrawer {
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.4em;
  width: 100%;
}

.titulo-drawer {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid grey;
}

.v-btn:before {
  opacity: 0;
}

.caractItems {
  font-size: 1em;
  color: white;
}

/*
Boton al Top
*/

#myBtn {
  border-radius: 5px;
  background-color: rgb(1, 14, 27);
  background-color: rgba(1, 14, 27, 0.7);
  color: white;
  position: fixed;
  width: 4em;
  height: 4em;
  display: block;
  right: 15px;
  bottom: 15px;
  border: none;
  opacity: 0.7;
  z-index: 9999;
}

#myBtn:hover {
  background-color: rgb(1, 14, 27);
  background-color: rgba(1, 14, 27, 0.9);
}

.titulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 2em;
  background: -webkit-linear-gradient(rgb(4, 31, 121), rgb(45, 110, 194));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titulo-blanco {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 2em;
  color: white;
}

.subtitulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 1.5em;
}

.texto {
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 650px) {
  .fa-2x {
    font-size: 1.2em;
    margin-right: 0;
  }

  .fa-5x {
    font-size: 3em;
  }

  .v-card__text {
    padding-top: 1em;
    font-size: 0.9em;
  }

  .v-card__title {
    padding: 0;
  }

  .marcasimg {
    height: 60px;
    max-width: 120px;
  }

  .texto {
    font-size: 1em;
  }
}

.mapa {
  width: 100%;
  height: 450px;

  @media screen and (max-width: 650px) {
    height: 250px;
  }
}
</style>

