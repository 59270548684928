<template>
  <section>

    <h2 class="titulo pt-3 text-center">Software de calidad</h2>

    <v-container
      grid-list-md
      text-center
    >
      <v-row
        row
        wrap
      >

        <v-flex
          xs12
          lg4
        >
          <v-card class="elevation-0 mx-3 tarsoft">
            <v-card-text class="px-0">
              <i class="fas fa-users fa-4x secondary--text"></i>
              <h3 class="text-center my-2 subtitulo">Personalizado</h3>
              <p class="texto">Desarrollamos software en la medida de la necesidad del cliente, ofreciendo a las pequeñas y medianas empresas soluciones informáticas</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          lg4
        >
          <v-card class="elevation-0 mx-2 tarsoft">
            <v-card-text class="px-0">
              <i class="fas fa-cogs fa-4x secondary--text"></i>
              <h3 class="text-center my-2 subtitulo">Mantenimiento</h3>
              <p class="texto">Ofrecemos servicio de mantemiento para nuestros programas, para garanzitar el correcto funcionamiento del mismo.</p>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          lg4
        >
          <v-card class="elevation-0 mx-3 tarsoft">
            <v-card-text class="px-0">
              <i class="fas fa-store fa-4x secondary--text"></i>
              <h3 class="text-center my-2 subtitulo">Tienda online</h3>
              <p class="texto"> Tienda con carrito de compra completa para sus productos, fácilmente administrable y con la posibilidad de personalizarla.</p>
            </v-card-text>
          </v-card>
        </v-flex>

      </v-row>

      <h3 class="text-center mt-3 subtitulo2 primary--text">Para todas las plataformas y dispositivos</h3>

      <v-row
        row
        wrap=""
        class="mt-3"
      >

        <v-flex
          xs12
          lg4
        >
          <v-card class="elevation-0 mx-2 tarsoft">
            <v-card-text class="px-0">
              <i class="fas fa-desktop fa-4x secondary--text"></i>
              <h3 class="text-center my-2 subtitulo">Escritorio</h3>

            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          lg4
        >
          <v-card class="elevation-0 mx-2 tarsoft">
            <v-card-text class="px-0">
              <i class="fas fa-mobile fa-4x secondary--text"></i>
              <h3 class="text-center my-2 subtitulo">Smartphones</h3>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          xs12
          lg4
        >
          <v-card class="elevation-0 mx-2 tarsoft">
            <v-card-text class="px-0">
              <i class="fab fa-chrome fa-4x secondary--text"></i>
              <i class="fab fa-firefox fa-4x secondary--text"></i>
              <i class="fab fa-edge fa-4x secondary--text"></i>
              <h3 class="text-center my-2 subtitulo">Web</h3>
            </v-card-text>
          </v-card>
        </v-flex>

      </v-row>
    </v-container>

  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$color-1: #0e40c6;
section {
  background-color: rgb(236, 236, 236);

  padding-top: 2em;
  padding-bottom: 2em;
  @media (max-width: 400px) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.tarsoft {
  background-color: rgb(236, 236, 236);
}

.v-card__text {
  background-color: rgb(236, 236, 236);
}

.titulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2em;
  color: $color-1;
}

.subtitulo {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 1.5em;
}

.subtitulo2 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.8em;
}

.texto {
  font-family: "Montserrat", sans-serif;

  @media (max-width: 600px) {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .fa-4x {
    font-size: 3em;
  }
}
</style>
