<template>
  <section id="section2">
    <!-- <img src="@/assets/hero.jpeg" alt=""> -->

    <v-row>
      <v-col
        cols="12"
        class="my-3"
      >
        <div class="text-center">
          <h2
            class="titulo-blanco pt-3"
            id="Servicios"
          >Administración completa para su negocios</h2>

        </div>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12">
        <v-container>
          <v-row
            row
            wrap
          >

            <v-col
              cols="12"
              md="6"
              class="imagen-contenedor text-center"
            >
              <img
                src="@/assets/mockup.png"
                class="imagen-pc"
                alt=""
                srcset=""
              >
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-card class="elevation-0 transparent">

                <v-list class="transparent">

                  <v-list-item>
                    <i class="far fa-check-circle primary--text text--lighten-2 fa-2x"></i>

                    <v-list-item-content>
                      <v-list-item-title class="white--text texto">Toda la tecnología que usted necesita</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <i class="far fa-check-circle primary--text text--lighten-2 fa-2x"></i>

                    <v-list-item-content>
                      <v-list-item-title class="white--text texto">Excelente calidad de servicios</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>

                    <i class="far fa-check-circle primary--text text--lighten-2 fa-2x"></i>

                    <v-list-item-content>
                      <v-list-item-title class="white--text texto">Usamos las más modernas tecnologías</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>

                    <i class="far fa-check-circle primary--text text--lighten-2 fa-2x"></i>

                    <v-list-item-content>
                      <v-list-item-title class="white--text texto">Brindamos servicios post-venta</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>

</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background-color: rgb(236, 236, 236);
}

h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.titulo-blanco {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2em;
  color: white;
}

.texto {
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 1em;
  }
}

#section2 {
  padding-top: 2em;
  padding-bottom: 2em;
  @media (max-width: 400px) {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  color: white;
  width: 100%;
  background: #000046; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #08718f,
    #000046
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #08718f,
    #000047
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* background: rgba(1,24,46,1);
background: -moz-linear-gradient(top, rgba(1,24,46,1) 0%, rgba(1,43,87,1) 52%, rgba(1,24,46,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(1,24,46,1)), color-stop(52%, rgba(1,43,87,1)), color-stop(100%, rgba(1,24,46,1)));
background: -webkit-linear-gradient(top, rgba(1,24,46,1) 0%, rgba(1,43,87,1) 52%, rgba(1,24,46,1) 100%);
background: -o-linear-gradient(top, rgba(1,24,46,1) 0%, rgba(1,43,87,1) 52%, rgba(1,24,46,1) 100%);
background: -ms-linear-gradient(top, rgba(1,24,46,1) 0%, rgba(1,43,87,1) 52%, rgba(1,24,46,1) 100%);
background: linear-gradient(to bottom, rgba(1,24,46,1) 0%, rgba(1,43,87,1) 52%, rgba(1,24,46,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01182e', endColorstr='#01182e', GradientType=0 ); */

  height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 600px) {
    height: 600px;
  }
}

@media screen and (min-width: 401px) and (max-width: 650px) {
  .brands {
    margin-top: 1em;
    font-size: 1.5em;
  }

  .fa-2x {
    font-size: 1.2em;
    margin-right: 0;
  }

  .v-list__tile__title {
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .fa-2x {
    font-size: 1.2em;
    margin-right: 0;
  }
  .v-list__tile__title {
    font-size: 0.8em;
  }
}

.imagen-pc {
  height: 250px;

  @media (max-width: 600px) {
    height: 125px;
    margin: 0 auto;
  }
}
</style>
