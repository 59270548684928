import Vue from "vue";
import VueRouter from "vue-router";
import appInicio from "../views/app-inicio.vue";

Vue.use(VueRouter);

const routes = [
  // Usar solo cuando %root.evSessionType >=1 (Ver ev-session.js)
  // -----------------------------v
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../evlib/evu-components/session/ev-login.vue"
      ),
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../evlib/evu-components/session/ev-logout.vue"
      ),
  },
  {
    path: "/change_password",
    name: "change_password",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../evlib/evu-components/session/ev-change-password.vue"
      ),
  },
  // -----------------------------^

  {
    path: "/inicio",
    name: "inicio",
    component: appInicio,
  },

  { path: "/", redirect: "/inicio" },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
